import * as React from "react";
import * as SectionStyle from "../../styles/index/sliderSection.module.scss";

import testingImg from "../../images/testing-image.jpg";

import sliderTwoLeft from "../../images/src/pages/index/desktop/slider_2_left.jpg";
import sliderTwoLeftR from "../../images/src/pages/index/desktop/slider_2_left@2x.jpg";
import sliderThreeLeft from "../../images/src/pages/index/desktop/slider_3_left.jpg";
import sliderThreeLeftR from "../../images/src/pages/index/desktop/slider_3_left@2x.jpg";
import sliderFourLeft from "../../images/src/pages/index/desktop/slider_4_left.jpg";
import sliderFourLeftR from "../../images/src/pages/index/desktop/slider_4_left@2x.jpg";
import sliderFiveLeft from "../../images/src/pages/index/desktop/slider_5_left.jpg";
import sliderFiveLeftR from "../../images/src/pages/index/desktop/slider_5_left@2x.jpg";

import sliderFiveRightDesktop from "../../images/src/pages/index/desktop/slider_5_right.jpg";
import sliderFiveRightDesktopR from "../../images/src/pages/index/desktop/slider_5_right@2x.jpg";
import sliderTwoRightDesktop from "../../images/src/pages/index/desktop/slider_2_right.jpg";
import sliderTwoRightDesktopR from "../../images/src/pages/index/desktop/slider_2_right@2x.jpg";
import sliderThreeRightDesktop from "../../images/src/pages/index/desktop/slider_3_right.jpg";
import sliderThreeRightDesktopR from "../../images/src/pages/index/desktop/slider_3_right@2x.jpg";
import sliderFourRightDesktop from "../../images/src/pages/index/desktop/slider_4_right.jpg";
import sliderFourRightDesktopR from "../../images/src/pages/index/desktop/slider_4_right@2x.jpg";

import gsap from "gsap";
import Button from "../utils/button";

class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.index = 0;
    this.picturesPrec = [];
    this.picturesCurrent = [];
  }

  componentDidMount() {
    this.picturesPrec = document.querySelectorAll(
      `.${SectionStyle.next} picture`
    );
    //console.log('Precedent image', this.picturesPrec);
    this.picturesCurrent = document.querySelectorAll(
      `.${SectionStyle.current} picture`
    );
    //console.log('Current Image', this.picturesCurrent)
    this.verify();
  }

  verify = () => {
    //console.log(this.index);
    this.picturesPrec.forEach((el) => (el.style.display = "none"));
    this.picturesCurrent.forEach((el) => (el.style.display = "none"));
    this.picturesPrec[
      this.index
    ].style.display = "block";
    this.picturesCurrent[
      this.index
    ].style.display = "block";
  };

  nextImage = () => {
    gsap.fromTo(
      ".js_cover",
      { left: "200%" },
      {
        left: 0,
        stagger: {
          each: 0.2,
          from: "end",
        },
        onComplete: () => {
          if (this.index >= this.picturesCurrent.length - 1) {
            this.index = 0;
            this.verify();
          } else {
            this.index = this.index + 1;
            this.verify();
          }
          gsap.fromTo(".js_cover", { left: 0 }, { left: "-200%" });
        },
      }
    );
  };

  precedentImage = () => {
    gsap.fromTo(
      ".js_cover",
      { left: "-200%" },
      {
        left: 0,
        stagger: {
          each: 0.2,
        },
        onComplete: () => {
          if (this.index === 0) {
            this.index = this.picturesCurrent.length - 1;
            this.verify();
          } else {
            this.index = this.index - 1;
            this.verify();
          }
          gsap.fromTo(".js_cover", { left: 0 }, { left: "200%" });
        },
      }
    );
  };

  render() {
    return (
      <section id={SectionStyle.wrapper}>
        <div className={SectionStyle.next + " js_div"}>
          <div>
          <picture>
              <img src={sliderFiveLeft} srcSet={sliderFiveLeftR + ' 2x, ' + sliderFiveLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
          <picture>
              <img src={sliderTwoLeft} srcSet={sliderTwoLeftR + ' 2x, ' + sliderTwoLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
          <picture>
              <img src={sliderThreeLeft} srcSet={sliderThreeLeftR + ' 2x, ' + sliderThreeLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
            <picture>
              <img src={sliderFourLeft} srcSet={sliderFourLeftR + ' 2x, ' + sliderFourLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
 
            <div className="js_cover"></div>
          </div>
        </div>
        <div className={SectionStyle.current + " js_div"}>
          <div>
          <picture>
          <source
              media="(min-width: 770px)"
              srcSet={sliderFiveRightDesktopR + " 2x, " + sliderFiveRightDesktop + " 1x"}
            />
              <img src={sliderTwoLeft} srcSet={sliderTwoLeftR + ' 2x, ' + sliderTwoLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
            <picture>
            <source
              media="(min-width: 770px)"
              srcSet={sliderTwoRightDesktopR + " 2x, " + sliderTwoRightDesktop + " 1x"}
            />
              <img src={sliderThreeLeft} srcSet={sliderThreeLeftR + ' 2x, ' + sliderThreeLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
            <picture>
            <source
              media="(min-width: 770px)"
              srcSet={sliderThreeRightDesktopR + " 2x, " + sliderThreeRightDesktop + " 1x"}
            />
              <img src={sliderFourLeft} srcSet={sliderFourLeftR + ' 2x, ' + sliderFourLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
            <picture>
            <source
              media="(min-width: 770px)"
              srcSet={sliderFourRightDesktopR + " 2x, " + sliderFourRightDesktop + " 1x"}
            />
              <img src={sliderFiveLeft} srcSet={sliderFiveLeftR + ' 2x, ' + sliderFiveLeft + ' 1x'} alt="Image temporaire"></img>
            </picture>
            <div className="js_cover"></div>
          </div>
          <div className={SectionStyle.big_wrapper}>
            <Button to="/realisations/amenagement" value="Réalisations" />
            <div className={SectionStyle.button_wrapper}>
              <button
                onClick={this.precedentImage}
                aria-label="Precedent-Image"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" fill="#23201D" />
                  <path
                    d="M21.4777 26L23 24.4725L18.0553 19.5L23 14.5275L21.4777 13L15 19.5L21.4777 26Z"
                    fill="#F3EBE2"
                  />
                </svg>
              </button>
              <button onClick={this.nextImage} aria-label="Image Suivante">
                <svg
                  width="75"
                  height="75"
                  viewBox="0 0 75 75"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="75" height="75" fill="#23201D" />
                  <path
                    d="M33.4737 27L31 29.4675L39.0351 37.5L31 45.5325L33.4737 48L44 37.5L33.4737 27Z"
                    fill="#F3EBE2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Slider;
