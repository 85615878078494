import * as React from "react";
import { SplitText } from "@cyriacbr/react-split-text";
import * as sectionStyle from "../../styles/index/headerSection.module.scss";
import eventBus from "../utils/eventBus";
import gsap from "gsap";

/*
import Picture1 from "../../images/src/pages/index/test/picture_one.jpg";
import Picture2 from "../../images/src/pages/index/test/picture_two.jpg";
import Picture3 from "../../images/src/pages/index/test/picture_three.jpg";
import Picture4 from "../../images/src/pages/index/test/picture_four.jpg";
import Picture5 from "../../images/src/pages/index/test/picture_five.jpg";
import Picture6 from "../../images/src/pages/index/test/picture_six.jpg";
*/

import Picture1 from "../../images/src/pages/index/test/picture_one.png";
import Picture2 from "../../images/src/pages/index/test/picture_two.png";
import Picture3 from "../../images/src/pages/index/test/picture_three.png";
import Picture4 from "../../images/src/pages/index/test/picture_four.png";
import Picture5 from "../../images/src/pages/index/test/picture_five.png";
import Picture6 from "../../images/src/pages/index/test/picture_six.png";
import Picture7 from "../../images/src/pages/index/test/picture_seven.png";

const HeaderSection = function (props) {
  let initied = null;
const anim = () =>{
  if ( document.querySelector('main').getAttribute('id') === '/') {
    gsap.fromTo(
      "h1 .line, p.line, #pined",
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        duration: 0.6,
        delay: 0.5,
        onStart: () => {
          gsap.set("h1", { opacity: 1 });
        },
      }
    );
  }
}

  React.useEffect(() => {
    gsap.set("h1, p.line, #pined", { opacity: 0 });
    eventBus.on("Finalised", anim);
    return (()=>{
      eventBus.remove("Finalised", anim);
      initied = null;
    })
  });

  React.useEffect(() => {
    console.log("Header Monted", props.scrollObject);
    if (props.scrollObject.gsap && initied == null) {
      if (window.innerWidth > 770) {
        initied = true;
        gsap.killTweensOf("#header, #pined");

        let tl = props.scrollObject.gsap.timeline({
          scrollTrigger: {
            scrub: true,
            trigger: "#header",
            start: "230 top",
            end: "bottom bottom",
            pin: "#pined",
          },
        });

        setTimeout(() => {
          let pageHeight = window.innerHeight;
          let wrapperHeight =
            document.querySelector("#center_pined").clientHeight;
          let diff = wrapperHeight - pageHeight;
          props.scrollObject.gsap.fromTo(
            "#center_pined",
            { translateY: 0 },
            {
              translateY: `-${diff + 0}px`,
              ease: "none",
              scrollTrigger: {
                scrub: true,
                trigger: "#header",
                start: "230 top",
                end: "bottom bottom",
                pin: "#pined",
              },
            }
          );

          tl.fromTo(
            "#i_header_main",
            { scale: 1.2 },
            { scale: 1, duration: 2 }
          );
          tl.fromTo(
            "." + sectionStyle.animatedLeft,
            { translateX: "-10vw", opacity: 0 },
            { translateX: 0, opacity: 1, duration: 2, delay: -1 }
          );
          tl.fromTo(
            "." + sectionStyle.animatedRight + " picture",
            { translateX: "10vw", opacity: 0 },
            {
              translateX: 0,
              opacity: 1,
              stagger: { each: 0.5, from: "end" },
              duration: 2,
              delay: -1,
            }
          );
          tl.fromTo(
            ".grid-wrapper-center picture",
            { translateY: "30vh", opacity: 0 },
            {
              translateY: 0,
              opacity: 1,
              ease: "power3.out",
              duration: 4,
              stagger: 0.5,
              delay: -1,
            }
          );

          props.scrollObject.obj.update();
        }, 10);
      }
    }
  }, [props.scrollObject]);
  return (
    <section className={sectionStyle.section} id="header">
      <div className={sectionStyle.section_header}>
        <h1>
          <SplitText
            LineWrapper={({ lineIndex, children }) => (
              <span className={"line wrapper" + " line" + lineIndex}>
                {children}
              </span>
            )}
          >
            Menuiserie Perret
          </SplitText>
        </h1>
        <p className="line">depuis 1906</p>
      </div>
      <div className={sectionStyle.animatedWrapper} id="pined">
        <div className={sectionStyle.centerPined} id="center_pined">
          <div className={sectionStyle.animatedLeft + " diff"}>
            <picture>
              <img src={Picture5}></img>
            </picture>
          </div>
          <div className={sectionStyle.animatedCenter + " diff"}>
            <div>
              <picture className={sectionStyle.mainPicture} id="i_header_main">
                <img src={Picture3}></img>
              </picture>
            </div>
            <div
              className={
                "grid-wrapper-center " + sectionStyle.gridWrapperCenter
              }
            >
              <picture>
                <img src={Picture4}></img>
              </picture>
              <picture>
                <img src={Picture7}></img>
              </picture>
              <picture>
                <img src={Picture6}></img>
              </picture>
            </div>
          </div>
          <div className={sectionStyle.animatedRight + " diff"}>
            <picture>
              <img src={Picture2}></img>
            </picture>
            <picture>
              <img src={Picture1}></img>
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderSection;
