import * as React from "react";
import Helmet from "react-helmet";
import {ScrollStateContext} from "../components/layout.jsx"

/** Import Style */
import "../styles/utils/header.scss";
import "../styles/index/introductionSection.scss";
import "../styles/index/detailsSection.scss";
import "../styles/index/boisSection.scss";
import "../styles/index/trustSection.scss";

/** Import Image */
import testingImg from "../images/testing-image.jpg";

import headerImgOne from "../images/src/pages/index/desktop/index_1_desktop.jpg";
import headerImgOneR from "../images/src/pages/index/desktop/index_1_desktop@2x.jpg";
import headerImgTwo from "../images/src/pages/index/desktop/index_2_desktop.jpg";
import headerImgTwoR from "../images/src/pages/index/desktop/index_2_desktop@2x.jpg";
import headerImgThree from "../images/src/pages/index/desktop/index_3_desktop.jpg";
import headerImgThreeR from "../images/src/pages/index/desktop/index_3_desktop@2x.jpg";

import headerImgOneMobile from "../images/src/pages/index/mobile/index_1_mobile.jpg";
import headerImgOneMobileR from "../images/src/pages/index/mobile/index_1_mobile@2x.jpg";
import headerImgTwoMobile from "../images/src/pages/index/mobile/index_2_mobile.jpg";
import headerImgTwoMobileR from "../images/src/pages/index/mobile/index_2_mobile@2x.jpg";
import headerImgThreeMobile from "../images/src/pages/index/mobile/index_3_mobile.jpg";
import headerImgThreeMobileR from "../images/src/pages/index/mobile/index_3_mobile@2x.jpg";

import trustImgOne from "../images/src/pages/index/desktop/index_4_desktop.jpg";
import trustImgOneR from "../images/src/pages/index/desktop/index_4_desktop@2x.jpg";
import trustImgTwo from "../images/src/pages/index/desktop/index_5_desktop.jpg";
import trustImgTwoR from "../images/src/pages/index/desktop/index_5_desktop@2x.jpg";
import trustImgThree from "../images/src/pages/index/desktop/index_6_desktop.jpg";
import trustImgThreeR from "../images/src/pages/index/desktop/index_6_desktop@2x.jpg";

import trustImgOneMobile from "../images/src/pages/index/desktop/index_4_desktop.jpg";
import trustImgOneMobileR from "../images/src/pages/index/desktop/index_4_desktop@2x.jpg";
import trustImgTwoMobile from "../images/src/pages/index/mobile/index_5_mobile.jpg";
import trustImgTwoMobileR from "../images/src/pages/index/mobile/index_5_mobile@2x.jpg";
import trustImgThreeMobile from "../images/src/pages/index/desktop/index_6_desktop.jpg";
import trustImgThreeMobileR from "../images/src/pages/index/desktop/index_6_desktop@2x.jpg";

import mesureImgDesktop from "../images/src/pages/index/desktop/index_7_desktop.jpg";
import mesureImgDesktopR from "../images/src/pages/index/desktop/index_7_desktop@2x.jpg";

import mesureImgMobile from "../images/src/pages/index/mobile/index_7_mobile.jpg";
import mesureImgMobileR from "../images/src/pages/index/mobile/index_7_mobile@2x.jpg";

import boisImgDesktop from "../images/src/pages/index/desktop/index_8_desktop.jpg";
import boisImgDesktopR from "../images/src/pages/index/desktop/index_8_desktop@2x.jpg";
/** Import Components */
import RippleSection from "../components/index/rippleSection";
import SliderSection from "../components/index/sliderSection";
import Button from "../components/utils/button";
import gsap from "gsap";
import eventBus from "../components/utils/eventBus";

// Logos Partenaires
import logo1 from "../images/src/partenaires/logo-auvergne-rhone-alpes_Perret2.png";
import logo2 from "../images/src/partenaires/logo-banque-de-france_Perret2.png";
import logo3 from "../images/src/partenaires/logo-bayer_Perret2.png";
import logo4 from "../images/src/partenaires/logo-CIC_Perret2.png";
import logo5 from "../images/src/partenaires/logo-creditagricole_Perret2.png";
import logo6 from "../images/src/partenaires/logo-croixrouge_Perret2.png";
import logo7 from "../images/src/partenaires/logo-GRANDLYON_Perret2.png";
import logo8 from "../images/src/partenaires/logo-MACIF_Perret2.png";
import logo9 from "../images/src/partenaires/logo-SNCF_Perret2.png";
import logo10 from "../images/src/partenaires/logo-villeurbanne_Perret2.png";
import HeaderSection from "../components/index/headerSection";


// markup
const IndexPage = () => {


  const boisObservable = () => {
    let target = document.querySelector(".bois");
    let prevRatio = 0.0;
    let prevY = 0.0;

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > prevRatio) {
          // Enter by top
          if (entry.boundingClientRect.y > prevY) {
            observer.unobserve(target);
          }
        } else {
        }
      });
    };
    let observer = new IntersectionObserver(callback);
    observer.observe(target);
  };

  const trustObservable = () => {
    let target = document.querySelector(".trust");
    let prevRatio = 0.0;
    let prevY = 0.0;

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > prevRatio) {
          // Enter by top
          if (entry.boundingClientRect.y > prevY) {
            gsap.fromTo(
              entry.target,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                duration: 1,
                ease: "power4.inOut",
              }
            );
            observer.unobserve(target);
          }
        } else {
        }
      });
    };
    let observer = new IntersectionObserver(callback);
    observer.observe(target);
  };

  const openDetail = (detail) => {
    console.log("open Details", detail);
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    let baseHeight = detail
      .querySelector("summary")
      .getBoundingClientRect().height;
    console.log("BaseHeight", baseHeight);
    detail.setAttribute("open", "true");
    let targetHeight = detail.getBoundingClientRect().height;
    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: baseHeight,
      },
      {
        height: targetHeight,
      }
    );
  };

  const closeDetail = (detail) => {
    detail.removeAttribute("style");
    gsap.killTweensOf(detail);
    gsap.killTweensOf(detail.querySelector("p"));
    detail.querySelector("p").style = " ";
    console.log("close Detail", detail);
    let tl = gsap.timeline();
    tl.fromTo(
      detail,
      {
        height: detail.getBoundingClientRect().height,
        ease: "easeOutExpo",
      },
      {
        height: detail.querySelector("summary").getBoundingClientRect().height,
        ease: "easeOutExpo",
        onComplete: () => {
          detail.removeAttribute("open");
        },
      }
    );
  };

  React.useEffect(() => {
   /* gsap.set("#Header", {
      opacity: 0,
    });
    eventBus.on("Finalised", () => {
      gsap.set("#Header", {
        opacity: 1,
      });
      let tl = gsap.timeline();

      tl.fromTo(
        ".js_title",
        {
          y: "200%",
        },
        {
          y: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        }
      );
      tl.fromTo(
        "#Header picture",
        {
          y: 20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          delay: -0.7,
          ease: "power3.out",
        }
      );

      /*   let tl = anime.timeline({
        easing: 'easeOutExpo',
        duration: 750
      });
      tl.add({targets: '.js_title',translateY: ['100%', '0'],delay: anime.stagger(100)} )
      tl.add({targets: '#Header picture',translateY: [20, '0'], opacity: [0, 1],  delay: anime.stagger(100)}); /**\
    });
    */
    // OPen one details at the same time
    const details = document.querySelectorAll(".details details");
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", (event) => {
        // Close all the details that are not targetDetail.
        event.preventDefault();

        details.forEach((detail) => {
          if (detail === targetDetail && !detail.getAttribute("open")) {
            openDetail(detail);
          } else {
            closeDetail(detail);
          }
        });
      });
    });

    //Intersection oberver installation
    boisObservable();
    trustObservable();
  });

  return (
    <>
      <Helmet>
        <title> Menuiserie Perret </title>
        <meta
          name="description"
          type="description"
          content="Spécialistes du bois et du sur-mesure, nous réalisons des ouvrages de tous types pour que nos clients se sentent bien dans leur environnement de travail et dans leur espace de vie."
        />
      </Helmet> 
      <ScrollStateContext.Consumer>
        {value => <HeaderSection scrollObject={value}/>}
      </ScrollStateContext.Consumer>
      {/* <section className="Header" id="Header">
        <p className="h1-top">
        
          <span className="js_title"> Menuiserie </span>
        </p>
        <picture id="h-picOne">
          <source
            media="(min-width: 770px)"
            srcSet={headerImgOneR + " 2x, " + headerImgOne + " 1x"}
          />
          <img
            src={headerImgOneMobile}
            srcSet={headerImgOneMobileR + " 2x, " + headerImgOneMobile + " 1x"}
            alt="Header texting img"
          >
          </img>
        </picture>
        <picture id="h-picTwo">
          <source
            media="(min-width: 770px)"
            srcSet={headerImgTwoR + " 2x, " + headerImgTwo + " 1x"}
          />
          <img
            src={headerImgTwoMobile}
            srcSet={headerImgTwoMobileR + " 2x, " + headerImgTwoMobile + " 1x"}
            alt="Header texting img"
          >
            
          </img>
        </picture>
        <picture id="h-picThree">
          <source
            media="(min-width: 770px)"
            srcSet={headerImgThreeR + " 2x, " + headerImgThree + " 1x"}
          />
          <img
            src={headerImgThreeMobile}
            srcSet={
              headerImgThreeMobileR + " 2x, " + headerImgThreeMobile + " 1x"
            }
            alt="Header texting img"
          ></img>
        </picture>
        <div className="h1-bottom">
          <p>
            <span className="js_title"> Perret </span>
          </p>
          <p className="small">
            <span className="js_title"> depuis 1906 </span>
          </p>
        </div>
      </section> */}
      <RippleSection />
      <section className="introduction" id="introduction">
        <div className="left">
          <h2 className="tertiary-title">
            Nous faisons presque tout ce qui est imaginable en menuiserie.
          </h2>
          <p>
            C’ est l’ avantage de maîtriser l’ ensemble de la chaîne, de la
            sélection du bois brut à la pose.
          </p>
          <Button to="/la-menuiserie" value="À propos" />
        </div>
        <div className="right">
          <picture className="parallax_elem" data-speed="1">
            <source
              media="(min-width: 770px)"
              srcSet={trustImgThreeR + " 2x, " + trustImgThree + " 1x"}
            />
            <img
              src={trustImgThreeMobile}
              srcSet={
                trustImgThreeMobileR + " 2x, " + trustImgThreeMobile + " 1x"
              }
              alt="Image de test"
            />
          </picture>
          <picture className="parallax_elem" data-speed="0">
            <source
              media="(min-width: 770px)"
              srcSet={trustImgTwoR + " 2x, " + trustImgTwo + " 1x"}
            />
            <img
              src={trustImgTwoMobile}
              srcSet={trustImgTwoMobileR + " 2x, " + trustImgTwoMobile + " 1x"}
              alt="Image de test"
            />
          </picture>
          <picture className="parallax_elem" data-speed="-1">
            <source
              media="(min-width: 770px)"
              srcSet={trustImgOneR + " 2x, " + trustImgOne + " 1x"}
            />
            <img
              src={trustImgOneMobile}
              srcSet={trustImgOneMobileR + " 2x, " + trustImgOneMobile + " 1x"}
              alt="Image de test"
            />
          </picture>
        </div>
      </section>
      <section className="details">
        <ul>
          <li className="js_details">
            <details className="details_first">
              <summary>
                <div>
                  <h3 className="tertiary-title"> Sur mesure </h3>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.125 17.875H6.875V15.125H26.125V17.875Z"
                      fill="#23201D"
                    />
                    <path
                      d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z"
                      fill="#23201D"
                    />
                  </svg>
                </div>
              </summary>
              <p>
                Nos réalisations sont conçues avec précision.Les menuisiers et
                ébénistes qui composent notre équipe sont passionnés par le
                travail du bois, ils conjuguent le respect de la longue
                tradition de la menuiserie et la maîtrise des technologies les
                plus modernes.
              </p>
            </details>
          </li>
          <li className="js_details">
            <details className="details_secondary">
              <summary>
                <div>
                  <h3 className="tertiary-title"> Qualité et précision </h3>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.125 17.875H6.875V15.125H26.125V17.875Z"
                      fill="#23201D"
                    />
                    <path
                      d="M15.125 26.125L15.125 6.875L17.875 6.875L17.875 26.125L15.125 26.125Z"
                      fill="#23201D"
                    />
                  </svg>
                </div>
              </summary>
              <p>
                Nous considérons que chaque réalisation est une création
                unique.Nous étudions chaque projet dans le but de concevoir des
                ouvrages adaptés, et nous conseillons nos clients pour que le
                résultat final réponde à leurs attentes.
              </p>
            </details>
          </li>
        </ul>
        <picture className="js_intersection" data-anim="classic">
          <source
            media="(min-width: 770px)"
            srcSet={mesureImgDesktopR + " 2x, " + mesureImgDesktop + " 1x"}
          />
          <img
            src={mesureImgMobile}
            srcSet={mesureImgMobileR + " 2x, " + mesureImgMobile + " 1x"}
            alt="Image de test"
          />
        </picture>
      </section>
      <SliderSection> </SliderSection>
      <section className="bois">
        <div className="js_left">
          <picture>
            <source
              media="(min-width: 770px)"
              srcSet={boisImgDesktopR + " 2x, " + boisImgDesktop + " 1x"}
            />
            <img src={boisImgDesktop} alt="image de test" />
          </picture>
          <h2 className="secondary-title">
            <span> B </span> <span> O </span> <span> I </span> <span> S </span>
          </h2>
        </div>
        <div className="js_right">
          <div className="content content-animation">
            <h3 className="tertiary-title title-anim">
              Souple et Robuste à la fois
            </h3>
            <p>
              A rebours des idées reçues, le bois est parmi les matériaux qui
              présentent la plus forte résistance à la rupture pour une densité
              parmi les plus faibles.Il est solide tout en étant léger, ce qui
              en fait un matériau particulièrement adapté pour concevoir des
              ouvrages sur mesure de qualité.De plus, ces performances
              naturelles sont aujourd 'hui décuplées par les techniques
              modernes.
            </p>
          </div>
        </div>
      </section>
      <section className="trust">
        <h3 className="tertiary-title"> Ils nous font confiances</h3>
        <ul>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo1}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo2}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo3}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo4}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo5}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo6}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo7}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo8}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo9}>
                
              </img>
            </a>
          </li>
          <li>
            <a>
              <img alt="Logo d'entreprise partenaire" src={logo10}>
                
              </img>
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default IndexPage;
