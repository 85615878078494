import * as React from "react";
import * as sectionStyle from "../../styles/index/rippleSection.module.scss";

import Picture1 from '../../images/src/ripples/Final - 01.jpg';
import Picture2 from '../../images/src/ripples/Final - 02.jpg';
import Picture3 from '../../images/src/ripples/Final - 03.jpg';
import Picture4 from '../../images/src/ripples/Final - 04.jpg';
import Picture5 from '../../images/src/ripples/Final - 05.jpg';
import Picture6 from '../../images/src/ripples/Final - 06.jpg';
import Picture7 from '../../images/src/ripples/Final - 07.jpg';
import Picture8 from '../../images/src/ripples/Final - 08.jpg';
import Picture9 from '../../images/src/ripples/Final - 09.jpg';
import Picture10 from '../../images/src/ripples/Final - 10.jpg';
import Picture11 from '../../images/src/ripples/Final - 11.jpg';
import Picture12 from '../../images/src/ripples/Final - 12.jpg';
import Picture13 from '../../images/src/ripples/Final - 13.jpg';
import Picture14 from '../../images/src/ripples/Final - 14.jpg';
import Picture15 from '../../images/src/ripples/Final - 15.jpg';
import Picture16 from '../../images/src/ripples/Final - 16.jpg';
import Picture17 from '../../images/src/ripples/Final - 17.jpg';
import Picture18 from '../../images/src/ripples/Final - 18.jpg';
import Picture19 from '../../images/src/ripples/Final - 19.jpg';
import Picture20 from '../../images/src/ripples/Final - 20.jpg';
import Picture21 from '../../images/src/ripples/Final - 21.jpg';
import Picture22 from '../../images/src/ripples/Final - 22.jpg';
import Picture23 from '../../images/src/ripples/Final - 23.jpg';
import Picture24 from '../../images/src/ripples/Final - 24.jpg';
import Picture25 from '../../images/src/ripples/Final - 25.jpg'
import Picture26 from '../../images/src/ripples/Final - 26.jpg';
import Picture27 from '../../images/src/ripples/Final - 27.jpg';
import Picture28 from '../../images/src/ripples/Final - 28.jpg';
import Picture29 from '../../images/src/ripples/Final - 29.jpg';
import Picture30 from '../../images/src/ripples/Final - 30.jpg';


import gsap from "gsap";

class RippleSection extends React.Component {
  constructor(props) {
    super(props);

    // Wrapper zone d'animation
    this.body = React.createRef();

    // Images à Animées
    this.images = "";

    // Total d'images
    this.imagesTotal = this.images.length;

    // upcoming image index
    this.imgPosition = 0;

    // zIndex value to apply to the upcoming image
    this.zIndexVal = 1;

    // Position de la souris
    this.mousePos = { x: 0, y: 0 };

    this.lastMousePos = { x: 0, y: 0 };

    // Minimal distance before showing next image
    this.threshold = 100;

    this.cacheMousePos = { x: 0, y: 0 };

    this.MathUtils = {
      // linear interpolation
      lerp: (a, b, n) => (1 - n) * a + n * b,
      // distance between two points
      distance: (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1),
    };

    this.prevRatio = 0.0;
    this.prevY = 0;
  }

  componentDidMount() {
    //console.log(this.body);
    this.images = this.body.current.querySelectorAll("picture");
    this.imagesTotal = this.images.length;
    //console.log(sectionStyle.section);
    if(window.innerWidth > 900){
      document.addEventListener("mousemove",this.setMousePosition, true);
    }
    this.renderTrail();
;  }

  setMousePosition = (e) => {
    this.mousePos = this.getMousePosition(e);
  }

  componentWillUnmount(){
    document.removeEventListener('mousemove', this.setMousePosition, true);
  }

  getMousePosition = (ev) => {
    let posx = 0;
    let posy = 0;
    if (!ev) ev = window.event;
    if (ev.pageX || ev.pageY) {
      posx = ev.pageX - this.body.current.offsetLeft;
      posy = ev.pageY - this.body.current.offsetTop;
    } else if (ev.clientX || ev.clientY) {
      posx = ev.clientX + this.body.current.scrollLeft;
      posy = ev.clientY + this.body.current.scrollTop;
    }
    return { x: posx, y: posy };
  };

  getMouseDistance = () => {
    return this.MathUtils.distance(
      this.mousePos.x,
      this.mousePos.y,
      this.lastMousePos.x,
      this.lastMousePos.y
    );
  };

  renderTrail() {
    let distance = this.getMouseDistance();
    //console.log("this is the distance", distance);
    // cache previous mouse position
    this.cacheMousePos.x = this.MathUtils.lerp(
      this.cacheMousePos.x || this.mousePos.x,
      this.mousePos.x,
      0.1
    );
    this.cacheMousePos.y = this.MathUtils.lerp(
      this.cacheMousePos.y || this.mousePos.y,
      this.mousePos.y,
      0.1
    );

    if (distance > this.threshold) {
      //console.log("Distance is on top");
      //console.log("All images before animation", this.images);
      //console.log(this.imagesTotal);
      //console.log("Current imgPosition", this.imgPosition);
      this.showNextImage();
      if (this.imgPosition < this.imagesTotal - 1) {
        this.imgPosition = this.imgPosition + 1;
      } else {
        this.imgPosition = 0;
      }
      this.lastMousePos = this.mousePos;
      ++this.zIndexVal;
    }
    window.requestAnimationFrame(() => this.renderTrail());
  }

  showNextImage() {
    const img = this.images[this.imgPosition];

    // console.log("Current Picture", img);

    gsap.killTweensOf(img);
    let tl = gsap.timeline();

    tl.set(
      img,
      {
        startAt: { opacity: 0, scale: 1 },
        opacity: 0.6,
        scale: 1,
        zIndex: this.zIndexVal,
        x: this.cacheMousePos.x - img.getBoundingClientRect().width ,
        y: this.cacheMousePos.y - img.getBoundingClientRect().height ,
      },
      0
    )
      // animate position
      .to(
        img,
        {
          duration: 0.9,
          ease: "Expo.easeOut",
          x: this.mousePos.x - img.getBoundingClientRect().width ,
          y: this.mousePos.y - img.getBoundingClientRect().height ,
        },
        0
      )
      // then make it disappear
      .to(
        img,
        {
          duration: 1,
          ease: "Power1.easeOut",
          opacity: 0,
        },
        0.4
      )
      // scale down the image
      .to(
        img,
        {
          duration: 1,
          ease: "Quint.easeOut",
          scale: 0.2,
        },
        0.4
      );
  }

  render() {
    return (
      <section className={sectionStyle.section} ref={this.body} id={sectionStyle.sectionID}>
        <div className={sectionStyle.back}>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture1} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture2} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture3} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture4} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture5} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture6} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture7} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture8} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture9} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture10} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture11} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture12} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture13} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture14} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture15} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture16} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture17} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture18} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture19} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture20} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture21} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture22} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture23} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture24} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture25} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture26} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture27} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture28} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture29} alt="Image d'effet pour illustrer" />
          </picture>
          <picture className={sectionStyle.back__picture}>
            <img src={Picture30} alt="Image d'effet pour illustrer" />
          </picture>
        </div>
        <div className={sectionStyle.front}>
          <h2 className="tertiary-title">
            Nous réalisons des ouvrages en bois de tous types pour que nos
            clients se sentent bien dans leur environnement de travail et leur
            espace de vie.{" "}
          </h2>
        </div>
      </section>
    );
  }
}

export default RippleSection;
